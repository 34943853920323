import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'

import LayoutFullWidth from '../../components/Layout/LayoutFullWidth'
import headerProps from '../../components/propTypes/headerProps'
import {
  Divider,
  MdxWrapper,
  PinBlockGroup,
  AggregatorPin4,
  CategoryTemplate,
  Typography,
} from '../../components'
import { path, pathOr, pipe, filter, sortBy } from 'ramda'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { format, isValid } from 'date-fns'

const propTypes = {
  data: PropTypes.shape({
    category: PropTypes.shape({
      frontmatter: PropTypes.shape({
        header: headerProps,
        title: PropTypes.string,
        summary: PropTypes.string,
        content: PropTypes.string,
      }),
    }),
    courses: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string,
            }),
            frontmatter: PropTypes.shape({
              title: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
  }),
}

export const CourseCategoryTemplate = ({ data }) => {
  const {
    category: {
      frontmatter: { title, summary, content },
    },
    courses: { edges: courses },
  } = data

  const breadcrumbs = [
    {
      label: 'Educating',
      url: '/educating',
    },
    {
      label: title,
    },
  ]

  const categoryCourses = pipe(
    filter(({ node }) =>
      pathOr([], ['frontmatter', 'categories'], node).includes(title),
    ),
    sortBy(({ node }) => path(['frontmatter', 'applicationDeadline'], node)),
  )(courses)

  return (
    <MDXProvider>
      <CategoryTemplate breadcrumbs={breadcrumbs}>
        <Divider thickness={4} />
        <Typography tag="h1" type="boxHeading" className="mb-8">
          {title}
        </Typography>
        <MdxWrapper>
          {summary && <div>{summary}</div>}
          {content && <MDXRenderer>{content}</MDXRenderer>}
        </MdxWrapper>
        {categoryCourses.length < 1 && (
          <>
            <Divider thickness={4} />
            <Typography tag="h2" className="mb-8">
              There are currently no courses available.
            </Typography>
          </>
        )}
        {categoryCourses.length > 0 && (
          <>
            <Divider thickness={4} />
            <Typography tag="h2" className="mb-8">
              Courses
            </Typography>
            <PinBlockGroup columns={2}>
              {categoryCourses.map(({ node }, i) => {
                return (
                  <AggregatorPin4
                    key={i}
                    title={path(['frontmatter', 'title'], node)}
                    thumbnailSrc={path(['frontmatter', 'featuredImage'], node)}
                    href={path(['fields', 'slug'], node)}
                  >
                    {isValid(
                      new Date(
                        path(['frontmatter', 'applicationDeadline'], node),
                      ),
                    ) && (
                        <>
                          <strong className="text-teal-light uppercase">
                            Application Deadline
                          </strong>
                          <br />
                          {format(
                            new Date(
                              path(['frontmatter', 'applicationDeadline'], node),
                            ),
                            'dd MMMM yyyy',
                          )}
                          <br />
                        </>
                      )}
                    {isValid(
                      new Date(path(['frontmatter', 'startDate'], node)),
                    ) && (
                        <>
                          <strong className="text-teal-light uppercase">
                            Course Starts
                          </strong>
                          <br />
                          {format(
                            new Date(path(['frontmatter', 'startDate'], node)),
                            'dd MMMM yyyy',
                          )}
                        </>
                      )}
                  </AggregatorPin4>
                )
              })}
            </PinBlockGroup>
          </>
        )}
      </CategoryTemplate>
    </MDXProvider>
  )
}

CourseCategoryTemplate.propTypes = propTypes

const CourseCategory = ({ data }) => {
  const {
    category: {
      frontmatter: { header },
    },
  } = data
  return (
    <LayoutFullWidth header={header}>
      <CourseCategoryTemplate data={data} />
    </LayoutFullWidth>
  )
}

CourseCategory.propTypes = propTypes

export default CourseCategory

/** @todo only fetch courses by category */
export const pageQuery = graphql`
  query CourseCategoryTemplate($slug: String!, $currentDate: Date) {
    category: mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        ...headerFields
        ...introFields
        title
        summary
      }
    }
    courses: allMdx(
      filter: {
        fields: { contentType: { eq: "courses" } }
        frontmatter: { applicationDeadline: { gte: $currentDate } }
      }
      limit: 1000
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            applicationDeadline
            startDate
            featuredImage {
              childCloudinaryMediaImage {
                fixed(width: 150) {
                  height
                  src
                  srcSet
                  width
                }
              }
            }
            categories
          }
        }
      }
    }
  }
`
